import React, { Component } from 'react';
import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Content,
    IntroQuote
} from '@bahaide/shared/components/InnerPage';
import InnerPage from '../../components/InnerPage';
import { Link } from 'gatsby';

function encode(data) {
    return Object.keys(data)
        .map(
            key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&');
}

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            submitted: false,
            'per-anruf': false,
            'per-email': false,
            treffen: false,
            'gdpr-check': false,
        };
    }

    handleChange = e => {
        const target = e.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ isLoading: true });
        const form = e.target;
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...this.state,
            }),
        })
            .then(() => this.setState({ isLoading: false, submitted: true }))
            .catch(error => alert(error));
    };

    render() {
        return (
            <InnerPage
                title="Kontakt"
            >
                <IntroHolder
                    title="Kontakt"
                    breadcrumbs={{
                        'current-item': 'Bahá’í kennenlernen',
                    }}
                >
                </IntroHolder>
                <TwoColumns>
                    <Content>
                        <div role="form">
                            <form
                                name="contact"
                                method="post"
                                data-netlify="true"
                                data-netlify-honeypot="bot-field"
                                onSubmit={this.handleSubmit}
                            >
                                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                                <input
                                    type="hidden"
                                    name="form-name"
                                    value="contact"
                                />
                                <p hidden>
                                    <label>
                                        Don’t fill this out:{' '}
                                        <input
                                            name="bot-field"
                                            onChange={this.handleChange}
                                        />
                                    </label>
                                </p>
                                <p>
                                    Ich würde gerne
                                    <span>
                                        <ul className="unstyled">
                                        <li className="first">
                                                <input
                                                    type="checkbox"
                                                    name="treffen"
                                                    checked={
                                                        this.state['treffen']
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                                <span className="wpcf7-list-item-label">
                                                    Bahá'í in meinem Wohnort
                                                    treffen und an Aktivitäten
                                                    teilnehmen
                                                </span>
                                            </li>
                                            <li>
                                                <input
                                                    type="checkbox"
                                                    name="per-anruf"
                                                    checked={
                                                        this.state['per-anruf']
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                                <span className="wpcf7-list-item-label">
                                                    Angerufen werden um mehr
                                                    über den Bahá'í Glauben zu
                                                    erfahren
                                                </span>
                                            </li>
                                            <li className="last">
                                                <input
                                                    type="checkbox"
                                                    name="per-email"
                                                    checked={
                                                        this.state['per-email']
                                                    }
                                                    onChange={this.handleChange}
                                                />
                                                <span className="wpcf7-list-item-label">
                                                    Per Email mehr über den
                                                    Bahá'í Glauben erfahren
                                                </span>
                                            </li>
                                        </ul>
                                    </span>
                                    <span className="your-name">
                                        <input
                                            type="text"
                                            name="name"
                                            size="40"
                                            aria-required="true"
                                            required
                                            placeholder="Name"
                                            onChange={this.handleChange}
                                        />
                                    </span>
                                    <br />
                                    <span className="your-home">
                                        <input
                                            type="text"
                                            name="wohnort"
                                            size="40"
                                            className=""
                                            aria-required="true"
                                            required
                                            placeholder="Wohnort"
                                            onChange={this.handleChange}
                                        />
                                    </span>
                                    <br />
                                    <span className="your-email">
                                        <input
                                            type="email"
                                            name="email"
                                            size="40"
                                            className=""
                                            aria-required="true"
                                            required
                                            placeholder="Email"
                                            onChange={this.handleChange}
                                        />
                                    </span>
                                    <br />
                                    <span className="your-number">
                                        <input
                                            type="tel"
                                            name="telefon"
                                            size="40"
                                            className=""
                                            placeholder="Telefonnummer (freiwillig)"
                                            onChange={this.handleChange}
                                        />
                                    </span>
                                    <br />
                                    <span className="your-message">
                                        <textarea
                                            name="nachricht"
                                            cols="40"
                                            rows="10"
                                            className=""
                                            aria-required="true"
                                            required
                                            placeholder="Nachricht"
                                            onChange={this.handleChange}
                                        />
                                    </span>
                                    <br />
                                    <br />
                                    <span className="gdpr-check">
                                        <input
                                            type="checkbox"
                                            name="gdpr-check"
                                            checked={this.state['gdpr-check']}
                                            onChange={this.handleChange}
                                            aria-required="true"
                                            required
                                        />
                                        <span className="wpcf7-list-item-label">
                                            Zustimmung zur Datenspeicherung lt.
                                            DSGVO
                                        </span>
                                    </span>
                                    <br />
                                    Ich stimme zu, dass meine Angaben aus dem
                                    Kontaktformular zur Beantwortung meiner
                                    Anfrage erhoben und verarbeitet werden. Die
                                    Daten werden nach abgeschlossener
                                    Bearbeitung Ihrer Anfrage gelöscht. Hinweis:
                                    Sie können Ihre Einwilligung jederzeit für
                                    die Zukunft per E-Mail an info@bahai.de
                                    widerrufen. Detaillierte Informationen zum
                                    Umgang mit Nutzerdaten finden Sie in unserer{' '}
                                    <Link to="/datenschutzerklaerung/">
                                        Datenschutzerklärung
                                    </Link>
                                    .
                                </p>
                                <p>
                                    <input
                                        className={
                                            this.state.submitted
                                                ? 'submitted'
                                                : ''
                                        }
                                        type="submit"
                                        value={
                                            this.state.isLoading
                                                ? '...'
                                                : this.state.submitted
                                                ? 'Gesendet'
                                                : 'Senden'
                                        }
                                        disabled={
                                            this.state['gdpr-check']
                                                ? false
                                                : true
                                        }
                                    />
                                </p>
                            </form>
                        </div>
                    </Content>
                    <Sidebar>
                        <p>
                        Bahá’í-Gemeinde in Deutschland
                        <br />
                        Generalsekretariat
                        <br />
                        Eppsteiner Straße 89
                        <br />
                        65719 Hofheim-Langenhain
                        <br />
                        <br />
                        Tel.: 06192/9929-0
                        <br />
                        Fax: 06192/99299-9
                        <br />
                        E-Mail:{' '}
                        <a class="mail" href="mailto:info@bahai.de">
                            info@bahai.de
                        </a>
                        <br />
                        <br />
                        Bürozeiten für telefonische Anfragen:
                        <br />
                        Montag – Freitag
                        <br />
                        10:30 – 12:30 Uhr und
                        <br />
                        13:30 – 16:00 Uhr
                        </p>
                        <p class="separator" />
                        <br />
                        <p>
                            Bahá’í­-Vertretung Berlin
                            <br />
                            Chausseestr. 103
                            <br />
                            10115 Berlin
                        <br />
                        <br />
                            Tel.: 030/288 77 183
                            <br />
                            Fax: 030/69 08 82 61
                            <br />
                            E-Mail:{' '}
                            <a class="mail" href="mailto:oea@bahai.de">
                                oea@bahai.de
                            </a>
                        </p>
                    </Sidebar>
                </TwoColumns>
            </InnerPage>
        );
    }
}
